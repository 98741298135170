/// <reference path='jquery-2.2.4.min.js' />
/// <reference path='jquery.nicescroll.min.js' />
/// <reference path='isotope.pkgd.min.js' />
/// <reference path='jquery.appear.min.js' />
/// <reference path='jquery.stellar.min.js' />
/// <reference path='jquery.bxslider.min.js' />
/// <reference path='jquery.matchHeight-min.js' />
/// <reference path='jquery.fs.boxer.min.js' />
/// <reference path='jquery.countTo.js' />
/// <reference path='jquery.scrollTo.min.js' />

'use strict';

var $window = $(window);

/* smartresize
================================================== */
(function($,sr){

	// debouncing function from John Hann
	// http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
	var debounce = function (func, threshold, execAsap) {
			var timeout;

			return function debounced () {
					var obj = this, args = arguments;
					function delayed () {
							if (!execAsap)
									func.apply(obj, args);
							timeout = null;
					};

					if (timeout)
							clearTimeout(timeout);
					else if (execAsap)
							func.apply(obj, args);

					timeout = setTimeout(delayed, threshold || 100);
			};
	}
	// smartresize 
	jQuery.fn[sr] = function(fn){  return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };
})(jQuery,'smartresize');

/* custom scroll
================================================== */
function _custom_scroll ()
{
	if ( device.desktop() )
	{
		$("html").niceScroll({
			cursorcolor:"#555",
			mousescrollstep: 50,
			scrollspeed: 70,
			zindex: 4,
			cursorborderradius: 0,
			cursorborder:0,
			horizrailenabled: false,
			cursorfixedheight:120,
			cursorwidth:"10px",
			disablemutationobserver: true
		});
	};
};

/* main menu
================================================== */
function _main_menu ()
{
	var nMenuToggler = document.getElementById('menu-toggler'),
		nHeader      = document.getElementById('header'),
		nNav         = document.getElementById('navigation'),

		jMenuToggler = $(nMenuToggler),
		jHeader      = $(nHeader),
		jNav         = $(nNav),

		jSubMenu     = jNav.find('.submenu'),
		jLink        = jNav.find('li a');

	if ( jSubMenu.length ) { jSubMenu.parents('li').addClass('has-children'); };

	if ( jNav.hasClass('navigation-anchor') ) {

		var wrp  = jNav.parents('.inner'),
			iTop = jNav.offset().top;

		window.onscroll = function() {
			if ( (window.pageYOffset || document.documentElement.scrollTop) > iTop ) {

				wrp.addClass('fixed');
			} else {

				wrp.removeClass('fixed');
			};
		};

		jLink.on('touchend click', function (e) {
			e.preventDefault();

			var _href = this.getAttribute('href');

			if ( jMenuToggler.is(':visible') ) {

				jMenuToggler.removeClass('active');
				jHeader.removeClass('expanded');
			}

			$.scrollTo( _href,
				1000,
				{
					easing: 'swing' ,
					offset: -80,
					'axis':'y'
				}
			);

			return false;
		});
	};

	jLink.on('touchend click', function (e) {
		var $this = $(this),
			$parent = $this.parent();

		if ( jMenuToggler.is(':visible') && $this.next(jSubMenu).length ) {

			if ( $this.next().is(':visible') ) {

				$parent.removeClass('drop_active');
				$this.next().slideUp('fast');

			} else {

				$this.closest('ul').find('li').removeClass('drop_active');
				$this.closest('ul').find('.submenu').slideUp('fast');
				$parent.addClass('drop_active');
				$this.next().slideDown('fast');
			};

			return false;
		};
	});

	jMenuToggler.on('touchend click', function (e) {
		e.preventDefault();

		var $this = $(this);

		$this.toggleClass('active');

		jHeader.toggleClass('expanded');

		if ( !$this.hasClass('active') ) {

			jSubMenu.css({ display : 'none' });
			$('.drop_active').removeClass('drop_active');
		};

		return false;
	});

	$window.smartresize(function(){
		if ($(this).width() > 767) {

			jMenuToggler.removeClass('active');
			jHeader.removeClass('expanded');
			jNav.find('.drop_active').removeClass('drop_active');
			jSubMenu.removeAttr('style');
		}
	});
};

/* bxslider
================================================== */
function _bx_slider ()
{
	var bxSlider = $('.bxslider').each(function(){
		var $this = $(this);
		$this.bxSlider({
			mode 			:	$this.data('mode') != 'undefined' ? $this.data('mode') : "horizontal",
			speed			:	$this.data('speed') != 'undefined' ? $this.data('speed') : 4000,
			slideWidth		:	$this.data('slidewidth') != 'undefined' ? $this.data('slidewidth') : 0,
			minSlides		:	$this.data('minslides') != 'undefined' ? $this.data('minslides') : 1,
			maxSlides		:	$this.data('maxslides') != 'undefined' ? $this.data('maxslides') : 1,
			slideMargin		:	$this.data('slidemargin') != 'undefined' ? $this.data('slidemargin') : 0,
			moveSlides		:	$this.data('moveslides') != 'undefined' ? $this.data('moveslides') : 0,
			nextSelector 	:	$this.data('nextselector') != 'undefined' ? $this.data('nextselector') : '',
			prevSelector	:	$this.data('prevselector') != 'undefined' ? $this.data('prevselector') : '',
			pager			:	$this.data('pager') != 'undefined' ? $this.data('pager') : true,
			pagerSelector	:	$this.data('pagerselector') != 'undefined' ? $this.data('pagerselector') : '',
			pagerCustom		:	$this.data('pagercustom') != 'undefined' ? $this.data('pagercustom') : '',
			auto			:	$this.data('auto') != 'undefined' ? $this.data('auto') : false,
			autoHover		:	$this.data('autoHover') != 'undefined' ? $this.data('autoHover') : true,
			adaptiveHeight	:	$this.data('adaptiveheight') != 'undefined' ? $this.data('adaptiveheight') : true,
			captions		:	$this.data('caption') != 'undefined' ? $this.data('captions') : false,
			preloadImages 	:	'all',
			nextText        :	'',
			prevText        :	'',
			controls		:	true,
			onSliderLoad: function(currentIndex){
				var self = this;
				var parent = self.parents('.bxslider-container');
				var arrow = parent.find('.control-btn');

				arrow.hover(
					function(){ self.trigger('mouseenter'); },
					function(){ self.trigger('mouseleave'); }
				);
			},
			onSlideAfter: function($slideElement, oldIndex, newIndex){
				if ( this.parents('.matchHeight-item').length ) {

					setTimeout(function () {
						$.fn.matchHeight._update()
					},200)
				}
			}
		});
	});
};

/* isotope sorting
================================================== */
function _isotope_sorting ()
{
	var nOptionSets = document.getElementById('option-set'),
		jOptionSets = $(nOptionSets);

	if ( jOptionSets.length > 0 ) {

		var jIsoContainer = $('.js-isotope'),
			jOptionLinks = jOptionSets.find('a');

		jOptionLinks.on('click', function(e) {
			var $this = $(this),
				currentOption = $this.data('cat');

			jOptionSets.find('.selected').removeClass('selected');
			$this.addClass('selected');

			if (currentOption !== '*') {
				currentOption = '.' + currentOption;
			}

			jIsoContainer.isotope({filter : currentOption});

			return false;
		});
	};
};

/* google map
================================================== */
function _g_map ()
{
	var maps = $('.g_map');

	if ( maps.length > 0 ) {

		maps.each(function() {
			var current_map = $(this);
			var latlng = new google.maps.LatLng(current_map.attr('data-longitude'), current_map.attr('data-latitude'));
			//var latlngmarker = new google.maps.LatLng(current_map.attr('data-marker-longitude'), current_map.attr('data-marker-latitude'));
			var point = current_map.attr('data-marker');

			var myOptions = {
				zoom: 14,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				mapTypeControl: false,
				scrollwheel: false,
				draggable: true,
				panControl: false,
				zoomControl: false,
				disableDefaultUI: true
			};

			var stylez = [
				{
					featureType: "all",
					elementType: "all",
					stylers: [
						{ saturation: -100 } // <-- THIS
					]
				}
			];

			var map = new google.maps.Map(current_map[0], myOptions);

			var mapType = new google.maps.StyledMapType(stylez, { name:"Grayscale" });
			map.mapTypes.set('Grayscale', mapType);
			map.setMapTypeId('Grayscale');

			var marker = new google.maps.Marker({
				map: map,
				// icon: {
				// 	size: new google.maps.Size(83,76),
				// 	origin: new google.maps.Point(0,0),
				// 	anchor: new google.maps.Point(29,76),
				// 	url: point
				// },
				position: latlng
			});

			google.maps.event.addDomListener(window, "resize", function() {
				var center = map.getCenter();
				google.maps.event.trigger(map, "resize");
				map.setCenter(center);
			});
		});
	}
};

/* parallax
================================================== */
function _parallax ()
{
	if ( device.desktop() )
	{
		$.stellar({
			scrollProperty: 'scroll',
			verticalOffset: 0,
			horizontalOffset: 0,
			horizontalScrolling: false
		});

		$window.smartresize(function(){
			$.stellar('refresh');
		});
	};
};

/* scroll to top
================================================== */
function _scrollTop ()
{
	var	nBtnToTopWrap = document.getElementById('btn-to-top-wrap'),
		jBtnToTopWrap = $(nBtnToTopWrap);

	if ( jBtnToTopWrap.length > 0 ) {

		var nBtnToTop = document.getElementById('btn-to-top'),
			jBtnToTop = $(nBtnToTop);

		jBtnToTop.on('click', function (e) {
			e.preventDefault();

			$('body,html').stop().animate({ scrollTop: 0 } , 1500);

			return false;
		});

		$window.on('scroll', function(e) {

			if ( $window.scrollTop() > jBtnToTop.data('visible-offset') ) {

				if ( jBtnToTopWrap.is(":hidden") ) {
					jBtnToTopWrap.fadeIn();
				};

			} else {

				if ( jBtnToTopWrap.is(":visible") ) {
					jBtnToTopWrap.fadeOut();
				};
			};
		});
	};
};

/* scroll to bottom
================================================== */
function _scrollBottom ()
{
	var nBtnToBottom = document.getElementById('go-bottom'),
		jBtnToBottom = $(nBtnToBottom);

	if ( jBtnToBottom.length > 0 ) {
		jBtnToBottom.on('click', function (e) {
			e.preventDefault();

			$('body,html').stop().animate({ scrollTop: document.documentElement.clientHeight -80 + "px"} , 1000);
		});
	}
};

/* accordion
================================================== */
function _accordion ()
{
	var oAccordion = $('.accordion-container');

	if ( oAccordion.length > 0 ) {

		var oAccItem = oAccordion.find('.accordion-item'),
			oAccTrigger = oAccordion.find('.accordion-toggler');

		oAccordion.each(function () {
			$(this).find('.accordion-item:eq(0)').addClass('active');
		});

		oAccTrigger.on('click', function (j) {
			j.preventDefault();

			var $this = $(this),
				parent = $this.parent(),
				dropDown = $this.next('article');

			parent.toggleClass('active').siblings(oAccItem).removeClass('active').find('article').not(dropDown).slideUp();

			dropDown.stop(false, true).slideToggle();

			return false;
		});
	};
};

/* tabs
================================================== */
function _tabs ()
{
	var oTab = $('.tab-container');

	if ( oTab.length > 0 ) {

		var oTabTrigger = oTab.find('nav a');

		oTab.each(function () {

			$(this)
				.find('nav a:eq(0)').addClass('active').end()
				.find('.tab-item:eq(0)').addClass('visible');
		});

		oTabTrigger.on('click', function (g) {
			g.preventDefault();

			var $this = $(this),
				index = $this.index(),
				parent = $this.closest('.tab-container');

			$this.addClass('active').siblings(oTabTrigger).removeClass('active');

			parent
				.find('.tab-item.visible').removeClass('visible').end()
				.find('.tab-item:eq(' + index + ')').addClass('visible');

			return false;
		});
	};
};

/* skills
================================================== */
function _skills (el)
{
	$('.skill-percent[data-percent]',el).each(function () {
		var progress = $(this),
			percentage = Math.ceil($(this).attr('data-percent'));

		$({countNum: 0}).animate({countNum: percentage}, {
			duration: 2000,
			easing:'linear',
			step: function() {
			// What todo on every count
				var pct = '';
				if(percentage == 0){
					pct = Math.floor(this.countNum) + '%';
				}

				else{
					pct = Math.floor(this.countNum+1) + '%';
				}

				progress.text(pct) && progress.siblings('.progress-bar').children().filter( ':first' ).css('width',pct);
			}
		});
	});
};

/* counters
================================================== */
function _count(el)
{
	$('.count',el).each(function() {
		if( !$(this).hasClass('animate') ) {

			$(this).countTo({
				from: 0,
				speed: 2000,
				refreshInterval: 100,
				onComplete: function() {
					$(this).addClass('animate');
				}
			});
		}
	});
};

/* boxer gall
================================================== */
function _gall ()
{
	var galleryElement = $("a[data-gallery]");

	if ( galleryElement.length > 0 ) {
		galleryElement.boxer({
			fixed: true,
			videoWidth: 800
		});
	}
};

/* equal height
================================================== */
function _equal_height ()
{
	$('.matchHeight-container').each(function() {
		$(this).find('.matchHeight-item').matchHeight({
			byRow: true,
			property: 'height'
		});
	});
};

$(document).ready(function(){

	/* custom scroll
	================================================== */
	/*
	if you want use custom scroll remove this comment 
	_custom_scroll();
	*/

	/* main menu
	================================================== */
	_main_menu();

	/* bxslider
	================================================== */
	_bx_slider();

	/* isotopeSort
	================================================== */
	_isotope_sorting();

	/* google map
	================================================== */
	_g_map();

	/* parallax
	================================================== */
	_parallax();

	/* scroll to top
	================================================== */
	_scrollTop();

	/* scroll to bottom
	================================================== */
	_scrollBottom();

	/* accordion
	================================================== */
	_accordion();

	/* tabs
	================================================== */
	_tabs();

	if ( $().appear )
	{
		/* skills
		================================================== */
		$('.skill-item').appear(function() {
			var _self = $(this);

			setTimeout(function() {
				_skills(_self);
			}, 200);
		});

		/* counters
		================================================== */
		$('.counter-item').appear(function() {
			var _self = $(this);

			setTimeout(function() {
				_count(_self);
			}, 200);
		});
	};

	/* boxer gall
	================================================== */
	_gall();

	/* equal height
	================================================== */
	_equal_height();
});

$window.load(function(){

	var jIsotope = $('.js-isotope');

	if ( jIsotope.length ) {

		jIsotope.isotope('layout');
	};
});